/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getStore = /* GraphQL */ `query GetStore($id: ID!) {
  getStore(id: $id) {
    id
    marketplace
    marketplaceId
    name
    enabled
    lastSync
    syncTimestamp
    accessKey
    active
    owner
    address {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    lastSyncCount
  }
}
` as GeneratedQuery<APITypes.GetStoreQueryVariables, APITypes.GetStoreQuery>;
export const listStores = /* GraphQL */ `query ListStores(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      marketplace
      marketplaceId
      name
      enabled
      lastSync
      syncTimestamp
      accessKey
      active
      owner
      address {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      lastSyncCount
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListStoresQueryVariables,
  APITypes.ListStoresQuery
>;
export const storesByEnabled = /* GraphQL */ `query StoresByEnabled(
  $enabled: String!
  $lastSync: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  storesByEnabled(
    enabled: $enabled
    lastSync: $lastSync
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      marketplace
      marketplaceId
      name
      enabled
      lastSync
      syncTimestamp
      accessKey
      active
      owner
      address {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      lastSyncCount
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StoresByEnabledQueryVariables,
  APITypes.StoresByEnabledQuery
>;
export const storesByOwner = /* GraphQL */ `query StoresByOwner(
  $owner: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  storesByOwner(
    owner: $owner
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      marketplace
      marketplaceId
      name
      enabled
      lastSync
      syncTimestamp
      accessKey
      active
      owner
      address {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      lastSyncCount
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StoresByOwnerQueryVariables,
  APITypes.StoresByOwnerQuery
>;
export const getOrderDetails = /* GraphQL */ `query GetOrderDetails($id: ID!) {
  getOrderDetails(id: $id) {
    id
    shipment {
      shippingChargeTotal {
        currency
        value
      }
      shipDate
      shippingNumber
      trackingNumber
      trackingUrl
      providerId
      providerName
      service
      serviceCode
      serviceName
      serviceAccount
      labelFile {
        bucket
        key
        region
      }
      customsInvoiceFile {
        bucket
        key
        region
      }
      parts {
        shippingNumber
        trackingNumber
        trackingUrl
      }
    }
    owner
    items {
      id
      title
      variantTitle
      sku
      price {
        currency
        value
      }
      weight
      quantity
      productId
      variantId
      origin
      originStateCode
    }
    fromAddress {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    boxes {
      weight {
        value
        unit
      }
      dimensions {
        width
        height
        length
        unit
      }
    }
    packageType
    shippingAddress {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    notes
    totalWeight
    totalPrice {
      currency
      value
    }
    customsInvoice {
      items {
        description
        hscode
        origin
        weight {
          value
          unit
        }
        quantity
        unitPrice
        currency
        sku
        originStateCode
      }
      shipperTaxId
      recipientTaxId
      billTarget
      billTo {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
    }
    shippingCost {
      currency
      value
    }
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDetailsQueryVariables,
  APITypes.GetOrderDetailsQuery
>;
export const listOrderDetails = /* GraphQL */ `query ListOrderDetails(
  $filter: ModelOrderDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shipment {
        shippingChargeTotal {
          currency
          value
        }
        shipDate
        shippingNumber
        trackingNumber
        trackingUrl
        providerId
        providerName
        service
        serviceCode
        serviceName
        serviceAccount
        labelFile {
          bucket
          key
          region
        }
        customsInvoiceFile {
          bucket
          key
          region
        }
        parts {
          shippingNumber
          trackingNumber
          trackingUrl
        }
      }
      owner
      items {
        id
        title
        variantTitle
        sku
        price {
          currency
          value
        }
        weight
        quantity
        productId
        variantId
        origin
        originStateCode
      }
      fromAddress {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      boxes {
        weight {
          value
          unit
        }
        dimensions {
          width
          height
          length
          unit
        }
      }
      packageType
      shippingAddress {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      notes
      totalWeight
      totalPrice {
        currency
        value
      }
      customsInvoice {
        items {
          description
          hscode
          origin
          weight {
            value
            unit
          }
          quantity
          unitPrice
          currency
          sku
          originStateCode
        }
        shipperTaxId
        recipientTaxId
        billTarget
        billTo {
          name
          attentionName
          email
          phone
          address1
          address2
          postalCode
          city
          stateCode
          countryCode
          commercial
        }
      }
      shippingCost {
        currency
        value
      }
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDetailsQueryVariables,
  APITypes.ListOrderDetailsQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    orderNumber
    storeId
    store {
      id
      marketplace
      marketplaceId
      name
      enabled
      lastSync
      syncTimestamp
      accessKey
      active
      owner
      address {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      lastSyncCount
    }
    status
    prevStatus
    statusMessage
    owner
    createdAt
    updatedAt
    email
    firstName
    lastName
    phone
    address1
    address2
    postalCode
    city
    stateCode
    countryCode
    trackingNumbers
    providerId
    providerName
    service
    serviceName
    serviceCode
    paymentIntentId
    fulfilledDate
    shippingTimestamp
    shippingState
    shippingStateError
    shippingStateErrorMessage
    icsReviewed
    fromPostalCode
    carrierStatus
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderNumber
      storeId
      store {
        id
        marketplace
        marketplaceId
        name
        enabled
        lastSync
        syncTimestamp
        accessKey
        active
        owner
        address {
          name
          attentionName
          email
          phone
          address1
          address2
          postalCode
          city
          stateCode
          countryCode
          commercial
        }
        lastSyncCount
      }
      status
      prevStatus
      statusMessage
      owner
      createdAt
      updatedAt
      email
      firstName
      lastName
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      trackingNumbers
      providerId
      providerName
      service
      serviceName
      serviceCode
      paymentIntentId
      fulfilledDate
      shippingTimestamp
      shippingState
      shippingStateError
      shippingStateErrorMessage
      icsReviewed
      fromPostalCode
      carrierStatus
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const ordersByOwner = /* GraphQL */ `query OrdersByOwner(
  $owner: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderNumber
      storeId
      store {
        id
        marketplace
        marketplaceId
        name
        enabled
        lastSync
        syncTimestamp
        accessKey
        active
        owner
        address {
          name
          attentionName
          email
          phone
          address1
          address2
          postalCode
          city
          stateCode
          countryCode
          commercial
        }
        lastSyncCount
      }
      status
      prevStatus
      statusMessage
      owner
      createdAt
      updatedAt
      email
      firstName
      lastName
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      trackingNumbers
      providerId
      providerName
      service
      serviceName
      serviceCode
      paymentIntentId
      fulfilledDate
      shippingTimestamp
      shippingState
      shippingStateError
      shippingStateErrorMessage
      icsReviewed
      fromPostalCode
      carrierStatus
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.OrdersByOwnerQueryVariables,
  APITypes.OrdersByOwnerQuery
>;
export const searchOrders = /* GraphQL */ `query SearchOrders(
  $filter: SearchableOrderFilterInput
  $sort: [SearchableOrderSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableOrderAggregationInput]
) {
  searchOrders(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      orderNumber
      storeId
      store {
        id
        marketplace
        marketplaceId
        name
        enabled
        lastSync
        syncTimestamp
        accessKey
        active
        owner
        address {
          name
          attentionName
          email
          phone
          address1
          address2
          postalCode
          city
          stateCode
          countryCode
          commercial
        }
        lastSyncCount
      }
      status
      prevStatus
      statusMessage
      owner
      createdAt
      updatedAt
      email
      firstName
      lastName
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      trackingNumbers
      providerId
      providerName
      service
      serviceName
      serviceCode
      paymentIntentId
      fulfilledDate
      shippingTimestamp
      shippingState
      shippingStateError
      shippingStateErrorMessage
      icsReviewed
      fromPostalCode
      carrierStatus
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
          }
        }
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.SearchOrdersQueryVariables,
  APITypes.SearchOrdersQuery
>;
export const getOrderBatch = /* GraphQL */ `query GetOrderBatch($id: ID!) {
  getOrderBatch(id: $id) {
    id
    owner
    createdAt
    orders
    ordersNumbers
    labelFile {
      bucket
      key
      region
    }
    items {
      id
      title
      variantTitle
      sku
      price {
        currency
        value
      }
      weight
      quantity
      productId
      variantId
      origin
      originStateCode
    }
    marketplaceId
    marketplace
    marketplaceName
    fromAddress {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    fromStateCode
  }
}
` as GeneratedQuery<
  APITypes.GetOrderBatchQueryVariables,
  APITypes.GetOrderBatchQuery
>;
export const listOrderBatches = /* GraphQL */ `query ListOrderBatches(
  $filter: ModelOrderBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      createdAt
      orders
      ordersNumbers
      labelFile {
        bucket
        key
        region
      }
      items {
        id
        title
        variantTitle
        sku
        price {
          currency
          value
        }
        weight
        quantity
        productId
        variantId
        origin
        originStateCode
      }
      marketplaceId
      marketplace
      marketplaceName
      fromAddress {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      fromStateCode
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListOrderBatchesQueryVariables,
  APITypes.ListOrderBatchesQuery
>;
export const orderBatchesByOwner = /* GraphQL */ `query OrderBatchesByOwner(
  $owner: String!
  $fromStateCodeCreatedAt: ModelOrderBatchOrderBatchesByOwnerCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  orderBatchesByOwner(
    owner: $owner
    fromStateCodeCreatedAt: $fromStateCodeCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      createdAt
      orders
      ordersNumbers
      labelFile {
        bucket
        key
        region
      }
      items {
        id
        title
        variantTitle
        sku
        price {
          currency
          value
        }
        weight
        quantity
        productId
        variantId
        origin
        originStateCode
      }
      marketplaceId
      marketplace
      marketplaceName
      fromAddress {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      fromStateCode
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.OrderBatchesByOwnerQueryVariables,
  APITypes.OrderBatchesByOwnerQuery
>;
export const getSourceAddress = /* GraphQL */ `query GetSourceAddress($id: ID!) {
  getSourceAddress(id: $id) {
    id
    owner
    alias
    name
    attentionName
    email
    phone
    address1
    address2
    postalCode
    city
    stateCode
    countryCode
    commercial
  }
}
` as GeneratedQuery<
  APITypes.GetSourceAddressQueryVariables,
  APITypes.GetSourceAddressQuery
>;
export const listSourceAddresses = /* GraphQL */ `query ListSourceAddresses(
  $filter: ModelSourceAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listSourceAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      alias
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSourceAddressesQueryVariables,
  APITypes.ListSourceAddressesQuery
>;
export const sourceAddressesByOwner = /* GraphQL */ `query SourceAddressesByOwner(
  $owner: String!
  $alias: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSourceAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  sourceAddressesByOwner(
    owner: $owner
    alias: $alias
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      alias
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SourceAddressesByOwnerQueryVariables,
  APITypes.SourceAddressesByOwnerQuery
>;
export const getLastOrderPackage = /* GraphQL */ `query GetLastOrderPackage($id: ID!) {
  getLastOrderPackage(id: $id) {
    id
    storeId
    owner
    packageType
    boxes {
      weight {
        value
        unit
      }
      dimensions {
        width
        height
        length
        unit
      }
    }
    customsInvoice {
      items {
        description
        hscode
        origin
        weight {
          value
          unit
        }
        quantity
        unitPrice
        currency
        sku
        originStateCode
      }
      shipperTaxId
      recipientTaxId
      billTarget
      billTo {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.GetLastOrderPackageQueryVariables,
  APITypes.GetLastOrderPackageQuery
>;
export const getBoxPreset = /* GraphQL */ `query GetBoxPreset($id: ID!) {
  getBoxPreset(id: $id) {
    id
    owner
    alias
    weight {
      value
      unit
    }
    dimensions {
      width
      height
      length
      unit
    }
  }
}
` as GeneratedQuery<
  APITypes.GetBoxPresetQueryVariables,
  APITypes.GetBoxPresetQuery
>;
export const listBoxPresets = /* GraphQL */ `query ListBoxPresets(
  $filter: ModelBoxPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoxPresets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      alias
      weight {
        value
        unit
      }
      dimensions {
        width
        height
        length
        unit
      }
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListBoxPresetsQueryVariables,
  APITypes.ListBoxPresetsQuery
>;
export const boxPresetsByOwner = /* GraphQL */ `query BoxPresetsByOwner(
  $owner: String!
  $alias: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBoxPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  boxPresetsByOwner(
    owner: $owner
    alias: $alias
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      alias
      weight {
        value
        unit
      }
      dimensions {
        width
        height
        length
        unit
      }
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.BoxPresetsByOwnerQueryVariables,
  APITypes.BoxPresetsByOwnerQuery
>;
export const getUserSettings = /* GraphQL */ `query GetUserSettings($id: ID!) {
  getUserSettings(id: $id) {
    id
    stripeId
    paymentMethodId
    cardLast4
    cardBrand
    test
  }
}
` as GeneratedQuery<
  APITypes.GetUserSettingsQueryVariables,
  APITypes.GetUserSettingsQuery
>;
export const listUserSettings = /* GraphQL */ `query ListUserSettings(
  $filter: ModelUserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stripeId
      paymentMethodId
      cardLast4
      cardBrand
      test
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingsQueryVariables,
  APITypes.ListUserSettingsQuery
>;
export const getShippingProvider = /* GraphQL */ `query GetShippingProvider($id: ID!) {
  getShippingProvider(id: $id) {
    id
    defaultProviderId
    owner
    name
    status
    service
    countryCode
  }
}
` as GeneratedQuery<
  APITypes.GetShippingProviderQueryVariables,
  APITypes.GetShippingProviderQuery
>;
export const listShippingProviders = /* GraphQL */ `query ListShippingProviders(
  $filter: ModelShippingProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  listShippingProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultProviderId
      owner
      name
      status
      service
      countryCode
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListShippingProvidersQueryVariables,
  APITypes.ListShippingProvidersQuery
>;
export const providersByOwner = /* GraphQL */ `query ProvidersByOwner(
  $owner: String!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelShippingProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  providersByOwner(
    owner: $owner
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      defaultProviderId
      owner
      name
      status
      service
      countryCode
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProvidersByOwnerQueryVariables,
  APITypes.ProvidersByOwnerQuery
>;
export const getRates = /* GraphQL */ `query GetRates($input: RatesInput!) {
  getRates(input: $input) {
    rates {
      providerId
      providerName
      service
      serviceName
      serviceCode
      daysInTransit
      deliveryByTime
      price {
        currency
        value
      }
      originalPrice {
        currency
        value
      }
    }
    errors {
      providerId
      providerName
      service
      message
    }
  }
}
` as GeneratedQuery<APITypes.GetRatesQueryVariables, APITypes.GetRatesQuery>;
export const getICSRefunds = /* GraphQL */ `query GetICSRefunds {
  getICSRefunds {
    orderId
    trackingNumber
    providerId
    providerName
    accountNumber
    address {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    pickupDate
    deliveryDate
    numBusinessDays
    hasFurtherance
  }
}
` as GeneratedQuery<
  APITypes.GetICSRefundsQueryVariables,
  APITypes.GetICSRefundsQuery
>;
export const getOrderRisk = /* GraphQL */ `query GetOrderRisk($orderId: String!, $storeId: String!) {
  getOrderRisk(orderId: $orderId, storeId: $storeId)
}
` as GeneratedQuery<
  APITypes.GetOrderRiskQueryVariables,
  APITypes.GetOrderRiskQuery
>;
export const lookForBatchOrders = /* GraphQL */ `query LookForBatchOrders($fromStateCode: String!) {
  lookForBatchOrders(fromStateCode: $fromStateCode) {
    id
    items {
      id
      title
      variantTitle
      sku
      price {
        currency
        value
      }
      weight
      quantity
      productId
      variantId
      origin
      originStateCode
    }
    fromAddress {
      name
      attentionName
      email
      phone
      address1
      address2
      postalCode
      city
      stateCode
      countryCode
      commercial
    }
    boxes {
      weight {
        value
        unit
      }
      dimensions {
        width
        height
        length
        unit
      }
    }
    orders {
      id
      shippingAddress {
        name
        attentionName
        email
        phone
        address1
        address2
        postalCode
        city
        stateCode
        countryCode
        commercial
      }
      orderNumber
      notes
      marketplace
      marketplaceName
    }
  }
}
` as GeneratedQuery<
  APITypes.LookForBatchOrdersQueryVariables,
  APITypes.LookForBatchOrdersQuery
>;
export const collectShopifySupplyData = /* GraphQL */ `query CollectShopifySupplyData($storeId: String!) {
  collectShopifySupplyData(storeId: $storeId) {
    locations {
      locationId
      locationName
      inventoryItems {
        variantId
        quantity
        inventoryCount
      }
    }
    products {
      variantId
      productId
      title
      inventoryCount
    }
  }
}
` as GeneratedQuery<
  APITypes.CollectShopifySupplyDataQueryVariables,
  APITypes.CollectShopifySupplyDataQuery
>;
