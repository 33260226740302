/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type SearchableOrderFilterInput = {
  id?: SearchableIDFilterInput | null,
  orderNumber?: SearchableStringFilterInput | null,
  storeId?: SearchableIDFilterInput | null,
  statusMessage?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  address1?: SearchableStringFilterInput | null,
  address2?: SearchableStringFilterInput | null,
  postalCode?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  stateCode?: SearchableStringFilterInput | null,
  countryCode?: SearchableStringFilterInput | null,
  trackingNumbers?: SearchableStringFilterInput | null,
  providerId?: SearchableIDFilterInput | null,
  providerName?: SearchableStringFilterInput | null,
  serviceName?: SearchableStringFilterInput | null,
  serviceCode?: SearchableStringFilterInput | null,
  paymentIntentId?: SearchableStringFilterInput | null,
  fulfilledDate?: SearchableStringFilterInput | null,
  shippingTimestamp?: SearchableIntFilterInput | null,
  shippingStateErrorMessage?: SearchableStringFilterInput | null,
  icsReviewed?: SearchableBooleanFilterInput | null,
  fromPostalCode?: SearchableStringFilterInput | null,
  carrierStatus?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  prevStatus?: SearchableStringFilterInput | null,
  service?: SearchableStringFilterInput | null,
  shippingState?: SearchableStringFilterInput | null,
  shippingStateError?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderFilterInput | null > | null,
  or?: Array< SearchableOrderFilterInput | null > | null,
  not?: SearchableOrderFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableOrderSortInput = {
  field?: SearchableOrderSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderSortableFields {
  id = "id",
  orderNumber = "orderNumber",
  storeId = "storeId",
  statusMessage = "statusMessage",
  owner = "owner",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  phone = "phone",
  address1 = "address1",
  address2 = "address2",
  postalCode = "postalCode",
  city = "city",
  stateCode = "stateCode",
  countryCode = "countryCode",
  trackingNumbers = "trackingNumbers",
  providerId = "providerId",
  providerName = "providerName",
  serviceName = "serviceName",
  serviceCode = "serviceCode",
  paymentIntentId = "paymentIntentId",
  fulfilledDate = "fulfilledDate",
  shippingTimestamp = "shippingTimestamp",
  shippingStateErrorMessage = "shippingStateErrorMessage",
  icsReviewed = "icsReviewed",
  fromPostalCode = "fromPostalCode",
  carrierStatus = "carrierStatus",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableOrderAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableOrderAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableOrderAggregateField {
  id = "id",
  orderNumber = "orderNumber",
  storeId = "storeId",
  status = "status",
  prevStatus = "prevStatus",
  statusMessage = "statusMessage",
  owner = "owner",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  phone = "phone",
  address1 = "address1",
  address2 = "address2",
  postalCode = "postalCode",
  city = "city",
  stateCode = "stateCode",
  countryCode = "countryCode",
  trackingNumbers = "trackingNumbers",
  providerId = "providerId",
  providerName = "providerName",
  service = "service",
  serviceName = "serviceName",
  serviceCode = "serviceCode",
  paymentIntentId = "paymentIntentId",
  fulfilledDate = "fulfilledDate",
  shippingTimestamp = "shippingTimestamp",
  shippingState = "shippingState",
  shippingStateError = "shippingStateError",
  shippingStateErrorMessage = "shippingStateErrorMessage",
  icsReviewed = "icsReviewed",
  fromPostalCode = "fromPostalCode",
  carrierStatus = "carrierStatus",
}


export type SearchableOrderConnection = {
  __typename: "SearchableOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type Order = {
  __typename: "Order",
  id: string,
  orderNumber: string,
  storeId?: string | null,
  store?: Store | null,
  status: OrderStatus,
  prevStatus?: OrderStatus | null,
  statusMessage?: string | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  email: string,
  firstName: string,
  lastName: string,
  phone?: string | null,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
  trackingNumbers?: Array< string > | null,
  providerId?: string | null,
  providerName?: string | null,
  service?: ShippingService | null,
  serviceName?: string | null,
  serviceCode?: string | null,
  paymentIntentId?: string | null,
  fulfilledDate?: string | null,
  shippingTimestamp: number,
  shippingState?: ShippingState | null,
  shippingStateError?: ShippingStateError | null,
  shippingStateErrorMessage?: string | null,
  icsReviewed?: boolean | null,
  fromPostalCode?: string | null,
  carrierStatus?: string | null,
};

export type Store = {
  __typename: "Store",
  // User ${owner}@${marketplaceId} - to enforce only single store per owner
  id: string,
  marketplace: Marketplace,
  marketplaceId: string,
  name: string,
  enabled: string,
  lastSync: string,
  syncTimestamp: number,
  accessKey: string,
  active: boolean,
  owner: string,
  address?: ShippingAddress | null,
  lastSyncCount?: number | null,
};

export enum Marketplace {
  Shopify = "Shopify",
  Amazon = "Amazon",
}


export type ShippingAddress = {
  __typename: "ShippingAddress",
  name: string,
  attentionName: string,
  email?: string | null,
  phone?: string | null,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
  commercial?: boolean | null,
};

export enum OrderStatus {
  Unfulfilled = "Unfulfilled",
  Fulfilled = "Fulfilled",
  Cancelled = "Cancelled",
  Pending = "Pending",
  Deleted = "Deleted",
}


export enum ShippingService {
  UPS = "UPS",
  CanadaPost = "CanadaPost",
  ICS = "ICS",
  Canpar = "Canpar",
}


export enum ShippingState {
  Recorded = "Recorded",
  Paid = "Paid",
  Shipped = "Shipped",
  Fulfilled = "Fulfilled",
  VoidPending = "VoidPending",
  Voided = "Voided",
  Refunded = "Refunded",
  Cancelled = "Cancelled",
}


export enum ShippingStateError {
  PaymentMethodUnset = "PaymentMethodUnset",
  PaymentConfirmationRequired = "PaymentConfirmationRequired",
  PaymentUnknownError = "PaymentUnknownError",
  ServiceError = "ServiceError",
  FulfillmentError = "FulfillmentError",
  VoidError = "VoidError",
  RefundError = "RefundError",
}


export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type CreateSourceAddressInput = {
  id?: string | null,
  owner: string,
  alias: string,
  name: string,
  attentionName: string,
  email?: string | null,
  phone?: string | null,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
  commercial?: boolean | null,
};

export type ModelSourceAddressConditionInput = {
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  name?: ModelStringInput | null,
  attentionName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  stateCode?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  commercial?: ModelBooleanInput | null,
  and?: Array< ModelSourceAddressConditionInput | null > | null,
  or?: Array< ModelSourceAddressConditionInput | null > | null,
  not?: ModelSourceAddressConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type SourceAddress = {
  __typename: "SourceAddress",
  id: string,
  owner: string,
  alias: string,
  name: string,
  attentionName: string,
  email?: string | null,
  phone?: string | null,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
  commercial?: boolean | null,
};

export type UpdateSourceAddressInput = {
  id: string,
  owner?: string | null,
  alias?: string | null,
  name?: string | null,
  attentionName?: string | null,
  email?: string | null,
  phone?: string | null,
  address1?: string | null,
  address2?: string | null,
  postalCode?: string | null,
  city?: string | null,
  stateCode?: string | null,
  countryCode?: string | null,
  commercial?: boolean | null,
};

export type DeleteSourceAddressInput = {
  id: string,
};

export type CreateBoxPresetInput = {
  id?: string | null,
  owner: string,
  alias: string,
  weight: WeightInput,
  dimensions: DimensionsInput,
};

export type WeightInput = {
  value: number,
  unit: WeightUnit,
};

export enum WeightUnit {
  kgs = "kgs",
  lbs = "lbs",
}


export type DimensionsInput = {
  width: number,
  height: number,
  length: number,
  unit: LengthUnit,
};

export enum LengthUnit {
  cm = "cm",
  in = "in",
}


export type ModelBoxPresetConditionInput = {
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  and?: Array< ModelBoxPresetConditionInput | null > | null,
  or?: Array< ModelBoxPresetConditionInput | null > | null,
  not?: ModelBoxPresetConditionInput | null,
};

export type BoxPreset = {
  __typename: "BoxPreset",
  id: string,
  owner: string,
  alias: string,
  weight: Weight,
  dimensions: Dimensions,
};

export type Weight = {
  __typename: "Weight",
  value: number,
  unit: WeightUnit,
};

export type Dimensions = {
  __typename: "Dimensions",
  width: number,
  height: number,
  length: number,
  unit: LengthUnit,
};

export type UpdateBoxPresetInput = {
  id: string,
  owner?: string | null,
  alias?: string | null,
  weight?: WeightInput | null,
  dimensions?: DimensionsInput | null,
};

export type DeleteBoxPresetInput = {
  id: string,
};

export type UserSettings = {
  __typename: "UserSettings",
  id: string,
  stripeId?: string | null,
  paymentMethodId?: string | null,
  cardLast4?: string | null,
  cardBrand?: string | null,
  test?: string | null,
};

export type ShippingProvider = {
  __typename: "ShippingProvider",
  id: string,
  defaultProviderId?: string | null,
  owner: string,
  name: string,
  status: ServiceStatus,
  service: ShippingService,
  countryCode?: string | null,
};

export enum ServiceStatus {
  Enabled = "Enabled",
  Disabled = "Disabled",
  Inactive = "Inactive",
}


export type RequestShopifyStoreAccessResult = {
  __typename: "RequestShopifyStoreAccessResult",
  authUrl?: string | null,
  errorCode?: ShopifyErrorCode | null,
};

export enum ShopifyErrorCode {
  StoreAlreadyInstalled = "StoreAlreadyInstalled",
  StoreInstalledForOtherUser = "StoreInstalledForOtherUser",
}


export type ShipOrderInput = {
  id: string,
  identityId?: string | null,
  packageType: PackageType,
  boxes: Array< BoxInput >,
  providerId: string,
  serviceName: string,
  serviceCode: string,
  from: ShippingAddressInput,
  to: ShippingAddressInput,
  price: MoneyInput,
  customsInvoice?: CustomsInvoiceInput | null,
  manual?: boolean | null,
};

export enum PackageType {
  box = "box",
  envelope = "envelope",
  pallet = "pallet",
}


export type BoxInput = {
  weight: WeightInput,
  dimensions: DimensionsInput,
};

export type ShippingAddressInput = {
  name: string,
  attentionName: string,
  email?: string | null,
  phone?: string | null,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
  commercial?: boolean | null,
};

export type MoneyInput = {
  currency: string,
  value: string,
};

export type CustomsInvoiceInput = {
  items: Array< InvoiceItemInput >,
  shipperTaxId?: string | null,
  recipientTaxId?: string | null,
  billTarget: BillTarget,
  billTo?: ShippingAddressInput | null,
};

export type InvoiceItemInput = {
  description: string,
  hscode: string,
  origin: string,
  weight?: WeightInput | null,
  quantity: number,
  unitPrice: string,
  currency: string,
  sku: string,
  originStateCode?: string | null,
};

export enum BillTarget {
  Shipper = "Shipper",
  Receiver = "Receiver",
}


export type CancelOrderInput = {
  id: string,
  identityId?: string | null,
};

export type AddShippingProviderInput = {
  id: string,
  name: string,
  service: ShippingService,
  status: ServiceStatus,
  credentials: string,
};

export type UpdateShippingProviderInput = {
  id: string,
  name: string,
  status: ServiceStatus,
};

export type OrderBatchInput = {
  batchId: string,
  identityId?: string | null,
  fromAddress: ShippingAddressInput,
  boxes: Array< BoxInput >,
  orders: Array< OrderBatchInputItem >,
};

export type OrderBatchInputItem = {
  id: string,
  providerId: string,
  serviceName: string,
  serviceCode: string,
  price: MoneyInput,
  toAddress: ShippingAddressInput,
};

export type OrderBatchResult = {
  __typename: "OrderBatchResult",
  batch?: OrderBatch | null,
  errors?:  Array<OrderBatchError > | null,
};

export type OrderBatch = {
  __typename: "OrderBatch",
  id: string,
  owner: string,
  createdAt: string,
  orders: Array< string >,
  ordersNumbers: Array< string >,
  labelFile?: S3Object | null,
  items?:  Array<OrderItem > | null,
  marketplaceId?: string | null,
  marketplace?: Marketplace | null,
  marketplaceName?: string | null,
  fromAddress?: ShippingAddress | null,
  fromStateCode?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  key: string,
  region: string,
};

export type OrderItem = {
  __typename: "OrderItem",
  id: string,
  title?: string | null,
  variantTitle?: string | null,
  sku?: string | null,
  price: Money,
  weight?: number | null,
  quantity: number,
  productId?: string | null,
  variantId?: string | null,
  origin?: string | null,
  originStateCode?: string | null,
};

export type Money = {
  __typename: "Money",
  currency: string,
  value: string,
};

export type OrderBatchError = {
  __typename: "OrderBatchError",
  orderId: string,
  message: string,
};

export type ModelStoreFilterInput = {
  id?: ModelIDInput | null,
  marketplace?: ModelMarketplaceInput | null,
  marketplaceId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  enabled?: ModelStringInput | null,
  lastSync?: ModelStringInput | null,
  syncTimestamp?: ModelIntInput | null,
  accessKey?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  lastSyncCount?: ModelIntInput | null,
  and?: Array< ModelStoreFilterInput | null > | null,
  or?: Array< ModelStoreFilterInput | null > | null,
  not?: ModelStoreFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelMarketplaceInput = {
  eq?: Marketplace | null,
  ne?: Marketplace | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStoreConnection = {
  __typename: "ModelStoreConnection",
  items:  Array<Store | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type OrderDetails = {
  __typename: "OrderDetails",
  id: string,
  shipment?: ShipmentInfo | null,
  owner: string,
  items?:  Array<OrderItem > | null,
  fromAddress?: ShippingAddress | null,
  boxes?:  Array<Box > | null,
  packageType?: PackageType | null,
  shippingAddress?: ShippingAddress | null,
  notes?: Array< string > | null,
  totalWeight?: number | null,
  totalPrice?: Money | null,
  customsInvoice?: CustomsInvoice | null,
  shippingCost?: Money | null,
};

export type ShipmentInfo = {
  __typename: "ShipmentInfo",
  shippingChargeTotal: Money,
  shipDate: string,
  shippingNumber: string,
  trackingNumber?: string | null,
  trackingUrl?: string | null,
  providerId?: string | null,
  providerName?: string | null,
  service: ShippingService,
  serviceCode: string,
  serviceName: string,
  serviceAccount?: string | null,
  labelFile?: S3Object | null,
  customsInvoiceFile?: S3Object | null,
  parts?:  Array<ShipmentPart > | null,
};

export type ShipmentPart = {
  __typename: "ShipmentPart",
  shippingNumber: string,
  trackingNumber?: string | null,
  trackingUrl?: string | null,
};

export type Box = {
  __typename: "Box",
  weight: Weight,
  dimensions: Dimensions,
};

export type CustomsInvoice = {
  __typename: "CustomsInvoice",
  items:  Array<InvoiceItem >,
  shipperTaxId?: string | null,
  recipientTaxId?: string | null,
  billTarget: BillTarget,
  billTo?: ShippingAddress | null,
};

export type InvoiceItem = {
  __typename: "InvoiceItem",
  description: string,
  hscode: string,
  origin: string,
  weight?: Weight | null,
  quantity: number,
  unitPrice: string,
  currency: string,
  sku: string,
  originStateCode?: string | null,
};

export type ModelOrderDetailsFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  packageType?: ModelPackageTypeInput | null,
  notes?: ModelStringInput | null,
  totalWeight?: ModelFloatInput | null,
  and?: Array< ModelOrderDetailsFilterInput | null > | null,
  or?: Array< ModelOrderDetailsFilterInput | null > | null,
  not?: ModelOrderDetailsFilterInput | null,
};

export type ModelPackageTypeInput = {
  eq?: PackageType | null,
  ne?: PackageType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelOrderDetailsConnection = {
  __typename: "ModelOrderDetailsConnection",
  items:  Array<OrderDetails | null >,
  nextToken?: string | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  orderNumber?: ModelStringInput | null,
  storeId?: ModelIDInput | null,
  status?: ModelOrderStatusInput | null,
  prevStatus?: ModelOrderStatusInput | null,
  statusMessage?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  stateCode?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  trackingNumbers?: ModelStringInput | null,
  providerId?: ModelIDInput | null,
  providerName?: ModelStringInput | null,
  service?: ModelShippingServiceInput | null,
  serviceName?: ModelStringInput | null,
  serviceCode?: ModelStringInput | null,
  paymentIntentId?: ModelStringInput | null,
  fulfilledDate?: ModelStringInput | null,
  shippingTimestamp?: ModelIntInput | null,
  shippingState?: ModelShippingStateInput | null,
  shippingStateError?: ModelShippingStateErrorInput | null,
  shippingStateErrorMessage?: ModelStringInput | null,
  icsReviewed?: ModelBooleanInput | null,
  fromPostalCode?: ModelStringInput | null,
  carrierStatus?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type ModelShippingServiceInput = {
  eq?: ShippingService | null,
  ne?: ShippingService | null,
};

export type ModelShippingStateInput = {
  eq?: ShippingState | null,
  ne?: ShippingState | null,
};

export type ModelShippingStateErrorInput = {
  eq?: ShippingStateError | null,
  ne?: ShippingStateError | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelOrderBatchFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  orders?: ModelIDInput | null,
  ordersNumbers?: ModelStringInput | null,
  marketplaceId?: ModelStringInput | null,
  marketplace?: ModelMarketplaceInput | null,
  marketplaceName?: ModelStringInput | null,
  fromStateCode?: ModelStringInput | null,
  and?: Array< ModelOrderBatchFilterInput | null > | null,
  or?: Array< ModelOrderBatchFilterInput | null > | null,
  not?: ModelOrderBatchFilterInput | null,
};

export type ModelOrderBatchConnection = {
  __typename: "ModelOrderBatchConnection",
  items:  Array<OrderBatch | null >,
  nextToken?: string | null,
};

export type ModelOrderBatchOrderBatchesByOwnerCompositeKeyConditionInput = {
  eq?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
  le?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
  lt?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
  ge?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
  gt?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
  between?: Array< ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null > | null,
  beginsWith?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput | null,
};

export type ModelOrderBatchOrderBatchesByOwnerCompositeKeyInput = {
  fromStateCode?: string | null,
  createdAt?: string | null,
};

export type ModelSourceAddressFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  name?: ModelStringInput | null,
  attentionName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  stateCode?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  commercial?: ModelBooleanInput | null,
  and?: Array< ModelSourceAddressFilterInput | null > | null,
  or?: Array< ModelSourceAddressFilterInput | null > | null,
  not?: ModelSourceAddressFilterInput | null,
};

export type ModelSourceAddressConnection = {
  __typename: "ModelSourceAddressConnection",
  items:  Array<SourceAddress | null >,
  nextToken?: string | null,
};

export type LastOrderPackage = {
  __typename: "LastOrderPackage",
  id: string,
  storeId: string,
  owner: string,
  packageType: PackageType,
  boxes:  Array<Box >,
  customsInvoice?: CustomsInvoice | null,
};

export type ModelBoxPresetFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  and?: Array< ModelBoxPresetFilterInput | null > | null,
  or?: Array< ModelBoxPresetFilterInput | null > | null,
  not?: ModelBoxPresetFilterInput | null,
};

export type ModelBoxPresetConnection = {
  __typename: "ModelBoxPresetConnection",
  items:  Array<BoxPreset | null >,
  nextToken?: string | null,
};

export type ModelUserSettingsFilterInput = {
  id?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  paymentMethodId?: ModelStringInput | null,
  cardLast4?: ModelStringInput | null,
  cardBrand?: ModelStringInput | null,
  test?: ModelStringInput | null,
  and?: Array< ModelUserSettingsFilterInput | null > | null,
  or?: Array< ModelUserSettingsFilterInput | null > | null,
  not?: ModelUserSettingsFilterInput | null,
};

export type ModelUserSettingsConnection = {
  __typename: "ModelUserSettingsConnection",
  items:  Array<UserSettings | null >,
  nextToken?: string | null,
};

export type ModelShippingProviderFilterInput = {
  id?: ModelIDInput | null,
  defaultProviderId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelServiceStatusInput | null,
  service?: ModelShippingServiceInput | null,
  countryCode?: ModelStringInput | null,
  and?: Array< ModelShippingProviderFilterInput | null > | null,
  or?: Array< ModelShippingProviderFilterInput | null > | null,
  not?: ModelShippingProviderFilterInput | null,
};

export type ModelServiceStatusInput = {
  eq?: ServiceStatus | null,
  ne?: ServiceStatus | null,
};

export type ModelShippingProviderConnection = {
  __typename: "ModelShippingProviderConnection",
  items:  Array<ShippingProvider | null >,
  nextToken?: string | null,
};

export type RatesInput = {
  from: RatesAddressInput,
  to: RatesAddressInput,
  packageType: PackageType,
  boxes: Array< BoxInput >,
  getOriginalPrice?: boolean | null,
  excludedServices?: Array< ShippingService > | null,
};

export type RatesAddressInput = {
  countryCode: string,
  stateCode: string,
  postalCode: string,
  city?: string | null,
  address1?: string | null,
  address2?: string | null,
  commercial?: boolean | null,
};

export type ShippingRatesResult = {
  __typename: "ShippingRatesResult",
  rates:  Array<ShippingOption >,
  errors?:  Array<ShippingRatesError > | null,
};

export type ShippingOption = {
  __typename: "ShippingOption",
  providerId: string,
  providerName: string,
  service: ShippingService,
  serviceName: string,
  serviceCode: string,
  daysInTransit?: string | null,
  deliveryByTime?: string | null,
  price: Money,
  originalPrice?: Money | null,
};

export type ShippingRatesError = {
  __typename: "ShippingRatesError",
  providerId: string,
  providerName: string,
  service: ShippingService,
  message: string,
};

export type ICSRefundItem = {
  __typename: "ICSRefundItem",
  orderId: string,
  trackingNumber: string,
  providerId: string,
  providerName: string,
  accountNumber: string,
  address: ShippingAddress,
  pickupDate: string,
  deliveryDate?: string | null,
  numBusinessDays: number,
  hasFurtherance: boolean,
};

export type OrderBatchData = {
  __typename: "OrderBatchData",
  id: string,
  items?:  Array<OrderItem > | null,
  fromAddress?: ShippingAddress | null,
  boxes?:  Array<Box > | null,
  orders:  Array<OrderBatchItemData >,
};

export type OrderBatchItemData = {
  __typename: "OrderBatchItemData",
  id: string,
  shippingAddress?: ShippingAddress | null,
  orderNumber?: string | null,
  notes?: Array< string > | null,
  marketplace?: Marketplace | null,
  marketplaceName?: string | null,
};

export type SupplyResultData = {
  __typename: "SupplyResultData",
  locations:  Array<LocationSupplyData >,
  products:  Array<ProductSupplyData >,
};

export type LocationSupplyData = {
  __typename: "LocationSupplyData",
  locationId: string,
  locationName: string,
  inventoryItems:  Array<InventorySupplyItemData >,
};

export type InventorySupplyItemData = {
  __typename: "InventorySupplyItemData",
  variantId: string,
  quantity: number,
  inventoryCount: number,
};

export type ProductSupplyData = {
  __typename: "ProductSupplyData",
  variantId: string,
  productId: string,
  title: string,
  inventoryCount: number,
};

export type SearchCountQueryVariables = {
  filter?: SearchableOrderFilterInput | null,
  sort?: Array< SearchableOrderSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableOrderAggregationInput | null > | null,
};

export type SearchCountQuery = {
  searchOrders?:  {
    __typename: "SearchableOrderConnection",
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CreateSourceAddressMutationVariables = {
  input: CreateSourceAddressInput,
  condition?: ModelSourceAddressConditionInput | null,
};

export type CreateSourceAddressMutation = {
  createSourceAddress?:  {
    __typename: "SourceAddress",
    id: string,
    owner: string,
    alias: string,
    name: string,
    attentionName: string,
    email?: string | null,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    commercial?: boolean | null,
  } | null,
};

export type UpdateSourceAddressMutationVariables = {
  input: UpdateSourceAddressInput,
  condition?: ModelSourceAddressConditionInput | null,
};

export type UpdateSourceAddressMutation = {
  updateSourceAddress?:  {
    __typename: "SourceAddress",
    id: string,
    owner: string,
    alias: string,
    name: string,
    attentionName: string,
    email?: string | null,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    commercial?: boolean | null,
  } | null,
};

export type DeleteSourceAddressMutationVariables = {
  input: DeleteSourceAddressInput,
  condition?: ModelSourceAddressConditionInput | null,
};

export type DeleteSourceAddressMutation = {
  deleteSourceAddress?:  {
    __typename: "SourceAddress",
    id: string,
    owner: string,
    alias: string,
    name: string,
    attentionName: string,
    email?: string | null,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    commercial?: boolean | null,
  } | null,
};

export type CreateBoxPresetMutationVariables = {
  input: CreateBoxPresetInput,
  condition?: ModelBoxPresetConditionInput | null,
};

export type CreateBoxPresetMutation = {
  createBoxPreset?:  {
    __typename: "BoxPreset",
    id: string,
    owner: string,
    alias: string,
    weight:  {
      __typename: "Weight",
      value: number,
      unit: WeightUnit,
    },
    dimensions:  {
      __typename: "Dimensions",
      width: number,
      height: number,
      length: number,
      unit: LengthUnit,
    },
  } | null,
};

export type UpdateBoxPresetMutationVariables = {
  input: UpdateBoxPresetInput,
  condition?: ModelBoxPresetConditionInput | null,
};

export type UpdateBoxPresetMutation = {
  updateBoxPreset?:  {
    __typename: "BoxPreset",
    id: string,
    owner: string,
    alias: string,
    weight:  {
      __typename: "Weight",
      value: number,
      unit: WeightUnit,
    },
    dimensions:  {
      __typename: "Dimensions",
      width: number,
      height: number,
      length: number,
      unit: LengthUnit,
    },
  } | null,
};

export type DeleteBoxPresetMutationVariables = {
  input: DeleteBoxPresetInput,
  condition?: ModelBoxPresetConditionInput | null,
};

export type DeleteBoxPresetMutation = {
  deleteBoxPreset?:  {
    __typename: "BoxPreset",
    id: string,
    owner: string,
    alias: string,
    weight:  {
      __typename: "Weight",
      value: number,
      unit: WeightUnit,
    },
    dimensions:  {
      __typename: "Dimensions",
      width: number,
      height: number,
      length: number,
      unit: LengthUnit,
    },
  } | null,
};

export type InitUserSettingsMutationVariables = {
};

export type InitUserSettingsMutation = {
  initUserSettings:  {
    __typename: "UserSettings",
    id: string,
    stripeId?: string | null,
    paymentMethodId?: string | null,
    cardLast4?: string | null,
    cardBrand?: string | null,
    test?: string | null,
  },
};

export type ToggleShippingProviderMutationVariables = {
  id: string,
  enabled: boolean,
};

export type ToggleShippingProviderMutation = {
  toggleShippingProvider:  {
    __typename: "ShippingProvider",
    id: string,
    defaultProviderId?: string | null,
    owner: string,
    name: string,
    status: ServiceStatus,
    service: ShippingService,
    countryCode?: string | null,
  },
};

export type RequestShopifyStoreAccessMutationVariables = {
  marketplaceId: string,
  shopName: string,
  daysToSync: number,
  query?: string | null,
};

export type RequestShopifyStoreAccessMutation = {
  requestShopifyStoreAccess:  {
    __typename: "RequestShopifyStoreAccessResult",
    authUrl?: string | null,
    errorCode?: ShopifyErrorCode | null,
  },
};

export type RequestUPSAccessMutationVariables = {
  state: string,
};

export type RequestUPSAccessMutation = {
  requestUPSAccess: string,
};

export type ConfirmUPSCallbackMutationVariables = {
  code: string,
  state: string,
  id: string,
  name: string,
  accountNumber: string,
};

export type ConfirmUPSCallbackMutation = {
  confirmUPSCallback:  {
    __typename: "ShippingProvider",
    id: string,
    defaultProviderId?: string | null,
    owner: string,
    name: string,
    status: ServiceStatus,
    service: ShippingService,
    countryCode?: string | null,
  },
};

export type InstallShopifyStoreMutationVariables = {
  query: string,
};

export type InstallShopifyStoreMutation = {
  installShopifyStore: boolean,
};

export type ActivateShopifyStoreMutationVariables = {
  query: string,
};

export type ActivateShopifyStoreMutation = {
  activateShopifyStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type DeactivateShopifyStoreMutationVariables = {
  id: string,
};

export type DeactivateShopifyStoreMutation = {
  deactivateShopifyStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type ToggleShopifyStoreMutationVariables = {
  id: string,
  enable: boolean,
};

export type ToggleShopifyStoreMutation = {
  toggleShopifyStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type ToggleAmazonStoreMutationVariables = {
  id: string,
  enable: boolean,
};

export type ToggleAmazonStoreMutation = {
  toggleAmazonStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type ChangeStoreMutationVariables = {
  id: string,
  name: string,
};

export type ChangeStoreMutation = {
  changeStore:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  },
};

export type InitSyncStoreMutationVariables = {
  id: string,
};

export type InitSyncStoreMutation = {
  initSyncStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type SyncStoreCompleteMutationVariables = {
  id: string,
  count: number,
};

export type SyncStoreCompleteMutation = {
  syncStoreComplete?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type NotifyOnOrderSyncMutationVariables = {
  json: string,
};

export type NotifyOnOrderSyncMutation = {
  notifyOnOrderSync?:  {
    __typename: "Order",
    id: string,
    orderNumber: string,
    storeId?: string | null,
    store?:  {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null,
    status: OrderStatus,
    prevStatus?: OrderStatus | null,
    statusMessage?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    trackingNumbers?: Array< string > | null,
    providerId?: string | null,
    providerName?: string | null,
    service?: ShippingService | null,
    serviceName?: string | null,
    serviceCode?: string | null,
    paymentIntentId?: string | null,
    fulfilledDate?: string | null,
    shippingTimestamp: number,
    shippingState?: ShippingState | null,
    shippingStateError?: ShippingStateError | null,
    shippingStateErrorMessage?: string | null,
    icsReviewed?: boolean | null,
    fromPostalCode?: string | null,
    carrierStatus?: string | null,
  } | null,
};

export type ShipOrderMutationVariables = {
  input: ShipOrderInput,
};

export type ShipOrderMutation = {
  shipOrder?:  {
    __typename: "Order",
    id: string,
    orderNumber: string,
    storeId?: string | null,
    store?:  {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null,
    status: OrderStatus,
    prevStatus?: OrderStatus | null,
    statusMessage?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    trackingNumbers?: Array< string > | null,
    providerId?: string | null,
    providerName?: string | null,
    service?: ShippingService | null,
    serviceName?: string | null,
    serviceCode?: string | null,
    paymentIntentId?: string | null,
    fulfilledDate?: string | null,
    shippingTimestamp: number,
    shippingState?: ShippingState | null,
    shippingStateError?: ShippingStateError | null,
    shippingStateErrorMessage?: string | null,
    icsReviewed?: boolean | null,
    fromPostalCode?: string | null,
    carrierStatus?: string | null,
  } | null,
};

export type SetupPaymentMethodMutationVariables = {
};

export type SetupPaymentMethodMutation = {
  setupPaymentMethod: string,
};

export type SavePaymentMethodMutationVariables = {
  paymentMethodId: string,
};

export type SavePaymentMethodMutation = {
  savePaymentMethod:  {
    __typename: "UserSettings",
    id: string,
    stripeId?: string | null,
    paymentMethodId?: string | null,
    cardLast4?: string | null,
    cardBrand?: string | null,
    test?: string | null,
  },
};

export type CancelOrderMutationVariables = {
  input: CancelOrderInput,
};

export type CancelOrderMutation = {
  cancelOrder?:  {
    __typename: "Order",
    id: string,
    orderNumber: string,
    storeId?: string | null,
    store?:  {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null,
    status: OrderStatus,
    prevStatus?: OrderStatus | null,
    statusMessage?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    trackingNumbers?: Array< string > | null,
    providerId?: string | null,
    providerName?: string | null,
    service?: ShippingService | null,
    serviceName?: string | null,
    serviceCode?: string | null,
    paymentIntentId?: string | null,
    fulfilledDate?: string | null,
    shippingTimestamp: number,
    shippingState?: ShippingState | null,
    shippingStateError?: ShippingStateError | null,
    shippingStateErrorMessage?: string | null,
    icsReviewed?: boolean | null,
    fromPostalCode?: string | null,
    carrierStatus?: string | null,
  } | null,
};

export type MarkOrdersAsReviewedMutationVariables = {
  orderIds: Array< string >,
};

export type MarkOrdersAsReviewedMutation = {
  markOrdersAsReviewed?: boolean | null,
};

export type AddShippingProviderMutationVariables = {
  input: AddShippingProviderInput,
};

export type AddShippingProviderMutation = {
  addShippingProvider:  {
    __typename: "ShippingProvider",
    id: string,
    defaultProviderId?: string | null,
    owner: string,
    name: string,
    status: ServiceStatus,
    service: ShippingService,
    countryCode?: string | null,
  },
};

export type UpdateShippingProviderMutationVariables = {
  input: UpdateShippingProviderInput,
};

export type UpdateShippingProviderMutation = {
  updateShippingProvider:  {
    __typename: "ShippingProvider",
    id: string,
    defaultProviderId?: string | null,
    owner: string,
    name: string,
    status: ServiceStatus,
    service: ShippingService,
    countryCode?: string | null,
  },
};

export type UpdateShipmentsStatusMutationVariables = {
};

export type UpdateShipmentsStatusMutation = {
  updateShipmentsStatus: number,
};

export type ShipOrderBatchMutationVariables = {
  input: OrderBatchInput,
};

export type ShipOrderBatchMutation = {
  shipOrderBatch:  {
    __typename: "OrderBatchResult",
    batch?:  {
      __typename: "OrderBatch",
      id: string,
      owner: string,
      createdAt: string,
      orders: Array< string >,
      ordersNumbers: Array< string >,
      labelFile?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        title?: string | null,
        variantTitle?: string | null,
        sku?: string | null,
        price:  {
          __typename: "Money",
          currency: string,
          value: string,
        },
        weight?: number | null,
        quantity: number,
        productId?: string | null,
        variantId?: string | null,
        origin?: string | null,
        originStateCode?: string | null,
      } > | null,
      marketplaceId?: string | null,
      marketplace?: Marketplace | null,
      marketplaceName?: string | null,
      fromAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      fromStateCode?: string | null,
    } | null,
    errors?:  Array< {
      __typename: "OrderBatchError",
      orderId: string,
      message: string,
    } > | null,
  },
};

export type RecoverOrderBatchMutationVariables = {
  id: string,
  identityId: string,
};

export type RecoverOrderBatchMutation = {
  recoverOrderBatch?:  {
    __typename: "OrderBatch",
    id: string,
    owner: string,
    createdAt: string,
    orders: Array< string >,
    ordersNumbers: Array< string >,
    labelFile?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      title?: string | null,
      variantTitle?: string | null,
      sku?: string | null,
      price:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      weight?: number | null,
      quantity: number,
      productId?: string | null,
      variantId?: string | null,
      origin?: string | null,
      originStateCode?: string | null,
    } > | null,
    marketplaceId?: string | null,
    marketplace?: Marketplace | null,
    marketplaceName?: string | null,
    fromAddress?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    fromStateCode?: string | null,
  } | null,
};

export type GetStoreQueryVariables = {
  id: string,
};

export type GetStoreQuery = {
  getStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type ListStoresQueryVariables = {
  filter?: ModelStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoresQuery = {
  listStores?:  {
    __typename: "ModelStoreConnection",
    items:  Array< {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StoresByEnabledQueryVariables = {
  enabled: string,
  lastSync?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StoresByEnabledQuery = {
  storesByEnabled?:  {
    __typename: "ModelStoreConnection",
    items:  Array< {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StoresByOwnerQueryVariables = {
  owner: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StoresByOwnerQuery = {
  storesByOwner?:  {
    __typename: "ModelStoreConnection",
    items:  Array< {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderDetailsQueryVariables = {
  id: string,
};

export type GetOrderDetailsQuery = {
  getOrderDetails?:  {
    __typename: "OrderDetails",
    id: string,
    shipment?:  {
      __typename: "ShipmentInfo",
      shippingChargeTotal:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      shipDate: string,
      shippingNumber: string,
      trackingNumber?: string | null,
      trackingUrl?: string | null,
      providerId?: string | null,
      providerName?: string | null,
      service: ShippingService,
      serviceCode: string,
      serviceName: string,
      serviceAccount?: string | null,
      labelFile?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      customsInvoiceFile?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      parts?:  Array< {
        __typename: "ShipmentPart",
        shippingNumber: string,
        trackingNumber?: string | null,
        trackingUrl?: string | null,
      } > | null,
    } | null,
    owner: string,
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      title?: string | null,
      variantTitle?: string | null,
      sku?: string | null,
      price:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      weight?: number | null,
      quantity: number,
      productId?: string | null,
      variantId?: string | null,
      origin?: string | null,
      originStateCode?: string | null,
    } > | null,
    fromAddress?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    boxes?:  Array< {
      __typename: "Box",
      weight:  {
        __typename: "Weight",
        value: number,
        unit: WeightUnit,
      },
      dimensions:  {
        __typename: "Dimensions",
        width: number,
        height: number,
        length: number,
        unit: LengthUnit,
      },
    } > | null,
    packageType?: PackageType | null,
    shippingAddress?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    notes?: Array< string > | null,
    totalWeight?: number | null,
    totalPrice?:  {
      __typename: "Money",
      currency: string,
      value: string,
    } | null,
    customsInvoice?:  {
      __typename: "CustomsInvoice",
      items:  Array< {
        __typename: "InvoiceItem",
        description: string,
        hscode: string,
        origin: string,
        weight?:  {
          __typename: "Weight",
          value: number,
          unit: WeightUnit,
        } | null,
        quantity: number,
        unitPrice: string,
        currency: string,
        sku: string,
        originStateCode?: string | null,
      } >,
      shipperTaxId?: string | null,
      recipientTaxId?: string | null,
      billTarget: BillTarget,
      billTo?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
    } | null,
    shippingCost?:  {
      __typename: "Money",
      currency: string,
      value: string,
    } | null,
  } | null,
};

export type ListOrderDetailsQueryVariables = {
  filter?: ModelOrderDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailsQuery = {
  listOrderDetails?:  {
    __typename: "ModelOrderDetailsConnection",
    items:  Array< {
      __typename: "OrderDetails",
      id: string,
      shipment?:  {
        __typename: "ShipmentInfo",
        shippingChargeTotal:  {
          __typename: "Money",
          currency: string,
          value: string,
        },
        shipDate: string,
        shippingNumber: string,
        trackingNumber?: string | null,
        trackingUrl?: string | null,
        providerId?: string | null,
        providerName?: string | null,
        service: ShippingService,
        serviceCode: string,
        serviceName: string,
        serviceAccount?: string | null,
        labelFile?:  {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null,
        customsInvoiceFile?:  {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null,
        parts?:  Array< {
          __typename: "ShipmentPart",
          shippingNumber: string,
          trackingNumber?: string | null,
          trackingUrl?: string | null,
        } > | null,
      } | null,
      owner: string,
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        title?: string | null,
        variantTitle?: string | null,
        sku?: string | null,
        price:  {
          __typename: "Money",
          currency: string,
          value: string,
        },
        weight?: number | null,
        quantity: number,
        productId?: string | null,
        variantId?: string | null,
        origin?: string | null,
        originStateCode?: string | null,
      } > | null,
      fromAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      boxes?:  Array< {
        __typename: "Box",
        weight:  {
          __typename: "Weight",
          value: number,
          unit: WeightUnit,
        },
        dimensions:  {
          __typename: "Dimensions",
          width: number,
          height: number,
          length: number,
          unit: LengthUnit,
        },
      } > | null,
      packageType?: PackageType | null,
      shippingAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      notes?: Array< string > | null,
      totalWeight?: number | null,
      totalPrice?:  {
        __typename: "Money",
        currency: string,
        value: string,
      } | null,
      customsInvoice?:  {
        __typename: "CustomsInvoice",
        items:  Array< {
          __typename: "InvoiceItem",
          description: string,
          hscode: string,
          origin: string,
          weight?:  {
            __typename: "Weight",
            value: number,
            unit: WeightUnit,
          } | null,
          quantity: number,
          unitPrice: string,
          currency: string,
          sku: string,
          originStateCode?: string | null,
        } >,
        shipperTaxId?: string | null,
        recipientTaxId?: string | null,
        billTarget: BillTarget,
        billTo?:  {
          __typename: "ShippingAddress",
          name: string,
          attentionName: string,
          email?: string | null,
          phone?: string | null,
          address1: string,
          address2: string,
          postalCode: string,
          city: string,
          stateCode: string,
          countryCode: string,
          commercial?: boolean | null,
        } | null,
      } | null,
      shippingCost?:  {
        __typename: "Money",
        currency: string,
        value: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    orderNumber: string,
    storeId?: string | null,
    store?:  {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null,
    status: OrderStatus,
    prevStatus?: OrderStatus | null,
    statusMessage?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    trackingNumbers?: Array< string > | null,
    providerId?: string | null,
    providerName?: string | null,
    service?: ShippingService | null,
    serviceName?: string | null,
    serviceCode?: string | null,
    paymentIntentId?: string | null,
    fulfilledDate?: string | null,
    shippingTimestamp: number,
    shippingState?: ShippingState | null,
    shippingStateError?: ShippingStateError | null,
    shippingStateErrorMessage?: string | null,
    icsReviewed?: boolean | null,
    fromPostalCode?: string | null,
    carrierStatus?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      orderNumber: string,
      storeId?: string | null,
      store?:  {
        __typename: "Store",
        // User ${owner}@${marketplaceId} - to enforce only single store per owner
        id: string,
        marketplace: Marketplace,
        marketplaceId: string,
        name: string,
        enabled: string,
        lastSync: string,
        syncTimestamp: number,
        accessKey: string,
        active: boolean,
        owner: string,
        address?:  {
          __typename: "ShippingAddress",
          name: string,
          attentionName: string,
          email?: string | null,
          phone?: string | null,
          address1: string,
          address2: string,
          postalCode: string,
          city: string,
          stateCode: string,
          countryCode: string,
          commercial?: boolean | null,
        } | null,
        lastSyncCount?: number | null,
      } | null,
      status: OrderStatus,
      prevStatus?: OrderStatus | null,
      statusMessage?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      email: string,
      firstName: string,
      lastName: string,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      trackingNumbers?: Array< string > | null,
      providerId?: string | null,
      providerName?: string | null,
      service?: ShippingService | null,
      serviceName?: string | null,
      serviceCode?: string | null,
      paymentIntentId?: string | null,
      fulfilledDate?: string | null,
      shippingTimestamp: number,
      shippingState?: ShippingState | null,
      shippingStateError?: ShippingStateError | null,
      shippingStateErrorMessage?: string | null,
      icsReviewed?: boolean | null,
      fromPostalCode?: string | null,
      carrierStatus?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByOwnerQuery = {
  ordersByOwner?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      orderNumber: string,
      storeId?: string | null,
      store?:  {
        __typename: "Store",
        // User ${owner}@${marketplaceId} - to enforce only single store per owner
        id: string,
        marketplace: Marketplace,
        marketplaceId: string,
        name: string,
        enabled: string,
        lastSync: string,
        syncTimestamp: number,
        accessKey: string,
        active: boolean,
        owner: string,
        address?:  {
          __typename: "ShippingAddress",
          name: string,
          attentionName: string,
          email?: string | null,
          phone?: string | null,
          address1: string,
          address2: string,
          postalCode: string,
          city: string,
          stateCode: string,
          countryCode: string,
          commercial?: boolean | null,
        } | null,
        lastSyncCount?: number | null,
      } | null,
      status: OrderStatus,
      prevStatus?: OrderStatus | null,
      statusMessage?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      email: string,
      firstName: string,
      lastName: string,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      trackingNumbers?: Array< string > | null,
      providerId?: string | null,
      providerName?: string | null,
      service?: ShippingService | null,
      serviceName?: string | null,
      serviceCode?: string | null,
      paymentIntentId?: string | null,
      fulfilledDate?: string | null,
      shippingTimestamp: number,
      shippingState?: ShippingState | null,
      shippingStateError?: ShippingStateError | null,
      shippingStateErrorMessage?: string | null,
      icsReviewed?: boolean | null,
      fromPostalCode?: string | null,
      carrierStatus?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrdersQueryVariables = {
  filter?: SearchableOrderFilterInput | null,
  sort?: Array< SearchableOrderSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableOrderAggregationInput | null > | null,
};

export type SearchOrdersQuery = {
  searchOrders?:  {
    __typename: "SearchableOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      orderNumber: string,
      storeId?: string | null,
      store?:  {
        __typename: "Store",
        // User ${owner}@${marketplaceId} - to enforce only single store per owner
        id: string,
        marketplace: Marketplace,
        marketplaceId: string,
        name: string,
        enabled: string,
        lastSync: string,
        syncTimestamp: number,
        accessKey: string,
        active: boolean,
        owner: string,
        address?:  {
          __typename: "ShippingAddress",
          name: string,
          attentionName: string,
          email?: string | null,
          phone?: string | null,
          address1: string,
          address2: string,
          postalCode: string,
          city: string,
          stateCode: string,
          countryCode: string,
          commercial?: boolean | null,
        } | null,
        lastSyncCount?: number | null,
      } | null,
      status: OrderStatus,
      prevStatus?: OrderStatus | null,
      statusMessage?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      email: string,
      firstName: string,
      lastName: string,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      trackingNumbers?: Array< string > | null,
      providerId?: string | null,
      providerName?: string | null,
      service?: ShippingService | null,
      serviceName?: string | null,
      serviceCode?: string | null,
      paymentIntentId?: string | null,
      fulfilledDate?: string | null,
      shippingTimestamp: number,
      shippingState?: ShippingState | null,
      shippingStateError?: ShippingStateError | null,
      shippingStateErrorMessage?: string | null,
      icsReviewed?: boolean | null,
      fromPostalCode?: string | null,
      carrierStatus?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetOrderBatchQueryVariables = {
  id: string,
};

export type GetOrderBatchQuery = {
  getOrderBatch?:  {
    __typename: "OrderBatch",
    id: string,
    owner: string,
    createdAt: string,
    orders: Array< string >,
    ordersNumbers: Array< string >,
    labelFile?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      title?: string | null,
      variantTitle?: string | null,
      sku?: string | null,
      price:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      weight?: number | null,
      quantity: number,
      productId?: string | null,
      variantId?: string | null,
      origin?: string | null,
      originStateCode?: string | null,
    } > | null,
    marketplaceId?: string | null,
    marketplace?: Marketplace | null,
    marketplaceName?: string | null,
    fromAddress?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    fromStateCode?: string | null,
  } | null,
};

export type ListOrderBatchesQueryVariables = {
  filter?: ModelOrderBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderBatchesQuery = {
  listOrderBatches?:  {
    __typename: "ModelOrderBatchConnection",
    items:  Array< {
      __typename: "OrderBatch",
      id: string,
      owner: string,
      createdAt: string,
      orders: Array< string >,
      ordersNumbers: Array< string >,
      labelFile?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        title?: string | null,
        variantTitle?: string | null,
        sku?: string | null,
        price:  {
          __typename: "Money",
          currency: string,
          value: string,
        },
        weight?: number | null,
        quantity: number,
        productId?: string | null,
        variantId?: string | null,
        origin?: string | null,
        originStateCode?: string | null,
      } > | null,
      marketplaceId?: string | null,
      marketplace?: Marketplace | null,
      marketplaceName?: string | null,
      fromAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      fromStateCode?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderBatchesByOwnerQueryVariables = {
  owner: string,
  fromStateCodeCreatedAt?: ModelOrderBatchOrderBatchesByOwnerCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderBatchesByOwnerQuery = {
  orderBatchesByOwner?:  {
    __typename: "ModelOrderBatchConnection",
    items:  Array< {
      __typename: "OrderBatch",
      id: string,
      owner: string,
      createdAt: string,
      orders: Array< string >,
      ordersNumbers: Array< string >,
      labelFile?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        title?: string | null,
        variantTitle?: string | null,
        sku?: string | null,
        price:  {
          __typename: "Money",
          currency: string,
          value: string,
        },
        weight?: number | null,
        quantity: number,
        productId?: string | null,
        variantId?: string | null,
        origin?: string | null,
        originStateCode?: string | null,
      } > | null,
      marketplaceId?: string | null,
      marketplace?: Marketplace | null,
      marketplaceName?: string | null,
      fromAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      fromStateCode?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSourceAddressQueryVariables = {
  id: string,
};

export type GetSourceAddressQuery = {
  getSourceAddress?:  {
    __typename: "SourceAddress",
    id: string,
    owner: string,
    alias: string,
    name: string,
    attentionName: string,
    email?: string | null,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    commercial?: boolean | null,
  } | null,
};

export type ListSourceAddressesQueryVariables = {
  filter?: ModelSourceAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSourceAddressesQuery = {
  listSourceAddresses?:  {
    __typename: "ModelSourceAddressConnection",
    items:  Array< {
      __typename: "SourceAddress",
      id: string,
      owner: string,
      alias: string,
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SourceAddressesByOwnerQueryVariables = {
  owner: string,
  alias?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSourceAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SourceAddressesByOwnerQuery = {
  sourceAddressesByOwner?:  {
    __typename: "ModelSourceAddressConnection",
    items:  Array< {
      __typename: "SourceAddress",
      id: string,
      owner: string,
      alias: string,
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLastOrderPackageQueryVariables = {
  id: string,
};

export type GetLastOrderPackageQuery = {
  getLastOrderPackage?:  {
    __typename: "LastOrderPackage",
    id: string,
    storeId: string,
    owner: string,
    packageType: PackageType,
    boxes:  Array< {
      __typename: "Box",
      weight:  {
        __typename: "Weight",
        value: number,
        unit: WeightUnit,
      },
      dimensions:  {
        __typename: "Dimensions",
        width: number,
        height: number,
        length: number,
        unit: LengthUnit,
      },
    } >,
    customsInvoice?:  {
      __typename: "CustomsInvoice",
      items:  Array< {
        __typename: "InvoiceItem",
        description: string,
        hscode: string,
        origin: string,
        weight?:  {
          __typename: "Weight",
          value: number,
          unit: WeightUnit,
        } | null,
        quantity: number,
        unitPrice: string,
        currency: string,
        sku: string,
        originStateCode?: string | null,
      } >,
      shipperTaxId?: string | null,
      recipientTaxId?: string | null,
      billTarget: BillTarget,
      billTo?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
    } | null,
  } | null,
};

export type GetBoxPresetQueryVariables = {
  id: string,
};

export type GetBoxPresetQuery = {
  getBoxPreset?:  {
    __typename: "BoxPreset",
    id: string,
    owner: string,
    alias: string,
    weight:  {
      __typename: "Weight",
      value: number,
      unit: WeightUnit,
    },
    dimensions:  {
      __typename: "Dimensions",
      width: number,
      height: number,
      length: number,
      unit: LengthUnit,
    },
  } | null,
};

export type ListBoxPresetsQueryVariables = {
  filter?: ModelBoxPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoxPresetsQuery = {
  listBoxPresets?:  {
    __typename: "ModelBoxPresetConnection",
    items:  Array< {
      __typename: "BoxPreset",
      id: string,
      owner: string,
      alias: string,
      weight:  {
        __typename: "Weight",
        value: number,
        unit: WeightUnit,
      },
      dimensions:  {
        __typename: "Dimensions",
        width: number,
        height: number,
        length: number,
        unit: LengthUnit,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BoxPresetsByOwnerQueryVariables = {
  owner: string,
  alias?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBoxPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BoxPresetsByOwnerQuery = {
  boxPresetsByOwner?:  {
    __typename: "ModelBoxPresetConnection",
    items:  Array< {
      __typename: "BoxPreset",
      id: string,
      owner: string,
      alias: string,
      weight:  {
        __typename: "Weight",
        value: number,
        unit: WeightUnit,
      },
      dimensions:  {
        __typename: "Dimensions",
        width: number,
        height: number,
        length: number,
        unit: LengthUnit,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSettingsQueryVariables = {
  id: string,
};

export type GetUserSettingsQuery = {
  getUserSettings?:  {
    __typename: "UserSettings",
    id: string,
    stripeId?: string | null,
    paymentMethodId?: string | null,
    cardLast4?: string | null,
    cardBrand?: string | null,
    test?: string | null,
  } | null,
};

export type ListUserSettingsQueryVariables = {
  filter?: ModelUserSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSettingsQuery = {
  listUserSettings?:  {
    __typename: "ModelUserSettingsConnection",
    items:  Array< {
      __typename: "UserSettings",
      id: string,
      stripeId?: string | null,
      paymentMethodId?: string | null,
      cardLast4?: string | null,
      cardBrand?: string | null,
      test?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingProviderQueryVariables = {
  id: string,
};

export type GetShippingProviderQuery = {
  getShippingProvider?:  {
    __typename: "ShippingProvider",
    id: string,
    defaultProviderId?: string | null,
    owner: string,
    name: string,
    status: ServiceStatus,
    service: ShippingService,
    countryCode?: string | null,
  } | null,
};

export type ListShippingProvidersQueryVariables = {
  filter?: ModelShippingProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingProvidersQuery = {
  listShippingProviders?:  {
    __typename: "ModelShippingProviderConnection",
    items:  Array< {
      __typename: "ShippingProvider",
      id: string,
      defaultProviderId?: string | null,
      owner: string,
      name: string,
      status: ServiceStatus,
      service: ShippingService,
      countryCode?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProvidersByOwnerQueryVariables = {
  owner: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShippingProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProvidersByOwnerQuery = {
  providersByOwner?:  {
    __typename: "ModelShippingProviderConnection",
    items:  Array< {
      __typename: "ShippingProvider",
      id: string,
      defaultProviderId?: string | null,
      owner: string,
      name: string,
      status: ServiceStatus,
      service: ShippingService,
      countryCode?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRatesQueryVariables = {
  input: RatesInput,
};

export type GetRatesQuery = {
  getRates:  {
    __typename: "ShippingRatesResult",
    rates:  Array< {
      __typename: "ShippingOption",
      providerId: string,
      providerName: string,
      service: ShippingService,
      serviceName: string,
      serviceCode: string,
      daysInTransit?: string | null,
      deliveryByTime?: string | null,
      price:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      originalPrice?:  {
        __typename: "Money",
        currency: string,
        value: string,
      } | null,
    } >,
    errors?:  Array< {
      __typename: "ShippingRatesError",
      providerId: string,
      providerName: string,
      service: ShippingService,
      message: string,
    } > | null,
  },
};

export type GetICSRefundsQueryVariables = {
};

export type GetICSRefundsQuery = {
  getICSRefunds:  Array< {
    __typename: "ICSRefundItem",
    orderId: string,
    trackingNumber: string,
    providerId: string,
    providerName: string,
    accountNumber: string,
    address:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    },
    pickupDate: string,
    deliveryDate?: string | null,
    numBusinessDays: number,
    hasFurtherance: boolean,
  } >,
};

export type GetOrderRiskQueryVariables = {
  orderId: string,
  storeId: string,
};

export type GetOrderRiskQuery = {
  getOrderRisk?: boolean | null,
};

export type LookForBatchOrdersQueryVariables = {
  fromStateCode: string,
};

export type LookForBatchOrdersQuery = {
  lookForBatchOrders:  Array< {
    __typename: "OrderBatchData",
    id: string,
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      title?: string | null,
      variantTitle?: string | null,
      sku?: string | null,
      price:  {
        __typename: "Money",
        currency: string,
        value: string,
      },
      weight?: number | null,
      quantity: number,
      productId?: string | null,
      variantId?: string | null,
      origin?: string | null,
      originStateCode?: string | null,
    } > | null,
    fromAddress?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    boxes?:  Array< {
      __typename: "Box",
      weight:  {
        __typename: "Weight",
        value: number,
        unit: WeightUnit,
      },
      dimensions:  {
        __typename: "Dimensions",
        width: number,
        height: number,
        length: number,
        unit: LengthUnit,
      },
    } > | null,
    orders:  Array< {
      __typename: "OrderBatchItemData",
      id: string,
      shippingAddress?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      orderNumber?: string | null,
      notes?: Array< string > | null,
      marketplace?: Marketplace | null,
      marketplaceName?: string | null,
    } >,
  } >,
};

export type CollectShopifySupplyDataQueryVariables = {
  storeId: string,
};

export type CollectShopifySupplyDataQuery = {
  collectShopifySupplyData:  {
    __typename: "SupplyResultData",
    locations:  Array< {
      __typename: "LocationSupplyData",
      locationId: string,
      locationName: string,
      inventoryItems:  Array< {
        __typename: "InventorySupplyItemData",
        variantId: string,
        quantity: number,
        inventoryCount: number,
      } >,
    } >,
    products:  Array< {
      __typename: "ProductSupplyData",
      variantId: string,
      productId: string,
      title: string,
      inventoryCount: number,
    } >,
  },
};

export type OnUpdateStoreSubscriptionVariables = {
  owner: string,
};

export type OnUpdateStoreSubscription = {
  onUpdateStore?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type OnStoreSyncSubscriptionVariables = {
  owner: string,
};

export type OnStoreSyncSubscription = {
  onStoreSync?:  {
    __typename: "Store",
    // User ${owner}@${marketplaceId} - to enforce only single store per owner
    id: string,
    marketplace: Marketplace,
    marketplaceId: string,
    name: string,
    enabled: string,
    lastSync: string,
    syncTimestamp: number,
    accessKey: string,
    active: boolean,
    owner: string,
    address?:  {
      __typename: "ShippingAddress",
      name: string,
      attentionName: string,
      email?: string | null,
      phone?: string | null,
      address1: string,
      address2: string,
      postalCode: string,
      city: string,
      stateCode: string,
      countryCode: string,
      commercial?: boolean | null,
    } | null,
    lastSyncCount?: number | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  owner: string,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    orderNumber: string,
    storeId?: string | null,
    store?:  {
      __typename: "Store",
      // User ${owner}@${marketplaceId} - to enforce only single store per owner
      id: string,
      marketplace: Marketplace,
      marketplaceId: string,
      name: string,
      enabled: string,
      lastSync: string,
      syncTimestamp: number,
      accessKey: string,
      active: boolean,
      owner: string,
      address?:  {
        __typename: "ShippingAddress",
        name: string,
        attentionName: string,
        email?: string | null,
        phone?: string | null,
        address1: string,
        address2: string,
        postalCode: string,
        city: string,
        stateCode: string,
        countryCode: string,
        commercial?: boolean | null,
      } | null,
      lastSyncCount?: number | null,
    } | null,
    status: OrderStatus,
    prevStatus?: OrderStatus | null,
    statusMessage?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    address1: string,
    address2: string,
    postalCode: string,
    city: string,
    stateCode: string,
    countryCode: string,
    trackingNumbers?: Array< string > | null,
    providerId?: string | null,
    providerName?: string | null,
    service?: ShippingService | null,
    serviceName?: string | null,
    serviceCode?: string | null,
    paymentIntentId?: string | null,
    fulfilledDate?: string | null,
    shippingTimestamp: number,
    shippingState?: ShippingState | null,
    shippingStateError?: ShippingStateError | null,
    shippingStateErrorMessage?: string | null,
    icsReviewed?: boolean | null,
    fromPostalCode?: string | null,
    carrierStatus?: string | null,
  } | null,
};
